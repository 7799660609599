import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
    name: 'GtrRegistrationChangePassword',
    computed: {
        ...mapState('auth', ['needAuthField']),
        ...mapState('event', ['event']),
        ...mapState('register', ['allContent'])
    }
})
export default class GtrRegistrationChangePassword extends GtrSuper {
    @Prop()
    template_name: any

    data() {
        return {
            password: '',
            password_confirmation: '',
            event_uuid: '',
            event_identifier: '',
            registration_uuid: '',
            pagenum: '',
            design: {},
            settings: {},
            loginFields: {},
            loginFieldData: {},
        }
    }

    //#region Watchers
    @Watch('event')
    async onEventChange(payload: any) {
        if (payload) {
            this.$data.event_uuid = payload.uuid
            if (this.$data.registration_uuid && this.$data.pagenum) {
                const data = {
                    participant_uuid: this.$data.registration_uuid,
                    pagenum: this.$data.pagenum,
                    event_identifier: this.$data.event_identifier,
                }
                try {
                    await this.$store.dispatch('auth/login', data)
                } catch (error) {
                    Container.get(ErrorHandlerService).error(error)
                }
            }
        }
    }

    @Watch('allContent')
    onAllContentChange(payload: any) {
        if (payload) {
            this.$data.design = payload.data.designSettings
            this.$data.settings = payload.data.settings
            this.$data.loginFields = this.$data.settings.login_fields
        }
    }
    //#endregion

    //#region Methods
    async updatePassword() {
        try {
            const payload: any = {
                event_identifier: this.$data.event_identifier,
                participant_uuid: this.$data.registration_uuid,
                new_password: this.$data.password,
                new_password_confirmation: this.$data.password_confirmation,
                password_token: this.$route.params.password_token,
                isDev: this.isDev,
            }
            await this.$store.dispatch('auth/updatePassword', payload)
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }
    //#endregion
}
